<template>
    <div>
        <headLine msg='校区统计'></headLine>
        <commonTop  @choseType="choseType">
            <div slot='exportBtn' class="export">
                <commonExport :exportUrl='exportUrl'></commonExport>
            </div>    
        </commonTop> 
        <tm-table v-bind:columns="columns" :dataSource="tableData" :hasIndex="true">
            <template slot="handleColumn">
                <el-table-column 
                label="操作"
                >
                    <template slot-scope="scope">
                        <el-button @click="lookClassDetail(scope.row.orgId)" 
                        type="primary"
                        size="small">班级统计</el-button>
                    </template>
                </el-table-column>
            </template>
        </tm-table>
    </div>
</template>
<script>
import commonTop from '../../components/commonTop'
export default {
    components:{
        commonTop
    },
    data() {
        return {
            columns:[
                {
                    hasSort:false,
                    prop:'orgName',
                    label:'校区名称'
                },
                {
                    hasSort:true,
                    prop:'deliveryNum',
                    label:'总投递量'
                },
                {
                    hasSort:true,
                    prop:'perCapitaNum',
                    label:'人均投递量'
                },
                {
                    hasSort:true,
                    prop:'resumePassNum',
                    label:'简历通过量'
                },
                {
                    hasSort:false,
                    prop:'resumePassPoint',
                    label:'简历通过率'
                },
                {
                    hasSort:true,
                    prop:'interviewNum',
                    label:'实际面试量'
                },
                {
                    hasSort:false,
                    prop:'toInterviewPoint',
                    label:'实际赴约率'
                },
                {
                    hasSort:true,
                    prop:'passNum',
                    label:'面试通过量'
                },
                {
                    hasSort:false,
                    prop:'passPoint',
                    label:'面试通过率'
                },
            ],
            tableData:[],
            exportUrl:''
        }
    },
    methods: {
        //查看班级详情
        lookClassDetail(orgId){
            this.$router.push({path:'/dbClassStatic',query:{id:orgId}})
        },
        choseType(type){
            this.getInitList(type)
        },
        getInitList(type){
            this.exportUrl=`/tea/org/statistics/export?type=${type}`
            let url=`/tea/org/statistics?type=${type}`  
            this.$htp.get_(url).then((res)=>{
                res.data.forEach(e=>{
                    e.resumePassPoint=e.resumePassPoint+'%'
                    e.toInterviewPoint=e.toInterviewPoint+'%'
                    e.passPoint=e.passPoint+'%'
                })
                this.tableData=res.data
            }) 
        },
    },
    mounted(){
        this.getInitList(0)
    }
}
</script>
<style lang="scss" scoped>
.export{
    position:absolute;
    top: 0px;
    right: 60px;
}
</style>